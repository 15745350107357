import React from 'react';

import pathwayImage1 from '../images/pathway-1.jpg'
import pathwayImage2 from '../images/pathway-2.jpg'
import pathwayImage3 from '../images/pathway-3.jpg'
import pathwayImage4 from '../images/pathway-4.jpg'

const ImageGroup = props => {

  return (
    <div className="pathway-person-images">
      <div className="pathway-image-col1">
        <div className="pathway-image" style={{backgroundImage: `url(${pathwayImage1})`}}></div>
        <div className="pathway-image" style={{backgroundImage: `url(${pathwayImage2})`}}></div>
      </div>
      <div className={"pathway-image-col2"}>
        <div className="pathway-image" style={{backgroundImage: `url(${pathwayImage3})`}}></div>
        <div className="pathway-image" style={{backgroundImage: `url(${pathwayImage4})`}}></div>
      </div>
    </div>
  )
}

export default ImageGroup