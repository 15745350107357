import React from "react"
import { Link } from "gatsby"

import Layout from "../layout/layout"
import Button from "../components/button"
import CourseCardGroup from "../components/courseCardGroup"
import ImageGroup from "../components/pathwayImageGroup"

import chooseIcon1 from "../images/chooseIcon1.svg"
import chooseIcon2 from "../images/chooseIcon2.svg"
import chooseIcon3 from "../images/chooseIcon3.svg"

import SUBJECTS_DATA from "../../data/subjects.json"

const VisionSection = () => {
  return (
    <div className="layout-container vision-wrapper">
      <div className="vision-container">
        <div className="vision-content">
          <h1>Your space, your pace, your style</h1>
          <p className="p-lg" style={{ marginTop: "35px" }}>
            Summit Institute is an accredited
            <strong> Category One NZQA private training provider </strong>
            offering flexible pathways to national qualifications.
          </p>
          <div className="enroll-button-group">
            <Link to="/subjects">
              <Button className="all-course-button">Explore all courses</Button>
            </Link>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSf3lEQ9MHRJFhrPFUfEqL4qxL_UrO28QJlnYjb27V2I1lYcvQ/viewform" target="_blank" class="btn-nl btn-sec enroll-button" style={{textDecoration:`none`}}>Enrol</a>
          </div>
        </div>
      </div>
    </div>
  )
}

const CourseSection = () => {
  const subjects = SUBJECTS_DATA.subjects

  return (
    <div className="light-blue-background">
      <div className="layout-container course-section-wrapper">
        <div className="course-section-container">
          <div className="course-section-upper">
            <div
              className="p-md"
              style={{ color: "#474747", marginBottom: "25px" }}
            >
              Subjects you can take
            </div>
            <h3>Find the right course for you</h3>
            <p className="p-lg course-right">
              We have amassed extensive experience in each of our subject areas
              and, as well as teaching and sitting on panels at NZQA to further
              define the unit standards, we have walked the talk in the real
              world.
            </p>
          </div>
          <CourseCardGroup subjects={subjects}></CourseCardGroup>
        </div>
      </div>
    </div>
  )
}

const PathwaySection = () => {
  return (
    <div className="layout-container pathway-section-container">
      <ImageGroup></ImageGroup>
      <div className="pathway-content">
        <div style={{ marginBottom: "60px" }}>
          <p
            className="p-md"
            style={{ color: "#474747", marginBottom: "25px" }}
          >
            Pathways you can take
          </p>
          <h4 style={{ marginBottom: "10px" }}>Qualifications</h4>
          <p className="p-md" style={{ marginBottom: "20px" }}>
            We can put together the contributing modules to earn NZ Certificates
            and Diplomas in a way that suits your needs and timelines.
          </p>
          <Link to="/subjects">
            <Button>Explore qualifications</Button>
          </Link>
        </div>
        <div>
          <h4 style={{ marginBottom: "10px" }}>Single or grouped units</h4>
          <p className="p-md" style={{ marginBottom: "20px" }}>
            Summit Institute is currently accredited by NZQA to deliver unit standards
            across multiple fields, subfields and domains generally linked to
            adult education, business and specialty skills.
          </p>
          <Button>Download full list</Button>
        </div>
      </div>
    </div>
  )
}

const HowStudySection = () => {
  return (
    <div className="primary-background">
      <div className="layout-container study-container">
        <div className="study-content">
          <p className="p-md" style={{ marginBottom: "25px", color: "white" }}>
            How you can study
          </p>
          <h2 style={{ marginBottom: "13px" }}>
          Blended learning is both in person & online, and has never been more relevant
          </h2>
          <p className="p-lg">
          A suite of online modules is available and more are being developed all the time! We know learners want different things so many modules have a smorgasbord of learning tools, resources and tips. You can pick and choose the ones you want to access.
          </p>
          <p className="p-lg p-i" style={{ marginTop: "20px" }}>
          If you need more information, please contact us.
          </p>
          <div className="study-btn-group">
            <Link to="/study">
              <Button
                style={{ marginRight: "20px", marginBottom: "20px" }}
                colorSchema="sec"
              >
                How you can study
              </Button>
            </Link>
            <Link to="/contact">
              <Button colorSchema="secOutline">Contact Us</Button>
            </Link>
          </div>
        </div>
        <div className="study-image"></div>
      </div>
    </div>
  )
}

const WhyChooseSection = () => {
  return (
    <div>
      <div className="why-choose">Why choose Summit Institute</div>
      <div className="layout-container choose-container">
        <div className="choose-card-container">
          <img
            src={chooseIcon1}
            style={{ marginBottom: "30px" }}
            alt="Accredited Professional Pathways"
          />
          <h6 class="choose-card-header">Accredited professional pathways</h6>
          <p style={{ textAlign: "center" }}>
            Accredited Category One NZQA private training provider with flexible
            learning pathways
          </p>
        </div>
        <div className="choose-card-container">
          <img
            src={chooseIcon2}
            style={{ marginBottom: "30px" }}
            alt="Personalised Learner Experiences"
          />
          <h6 class="choose-card-header">Personalised learner experiences</h6>
          <p style={{ textAlign: "center" }}>
            Learn virtually anywhere with a learning experience that is
            personalised to your learning pace and style
          </p>
        </div>
        <div className="choose-card-container">
          <img
            src={chooseIcon3}
            style={{ marginBottom: "30px" }}
            alt="Highly Trusted"
          />
          <h6 class="choose-card-header">Highly trusted</h6>
          <p style={{ textAlign: "center" }}>
            With our team of specialist learning professionals we deliver your
            learning on a highly secure, privacy focused platform
          </p>
        </div>
      </div>
      <div className="layout-container extrainfo-container" style={{marginTop:"-150px",marginBottom:"100px"}}>
          <p className="p-lg" style={{marginBottom:"20px"}}>
          Te Ara Teitei Summit Institute trains students around Aotearoa New Zealand.
          </p>
          <p className="p-lg" style={{marginBottom:"20px"}}>
          Currently, our students are 100% Kiwis, with a range of ages.</p>
          <p className="p-lg" style={{ fontWeight:"bold", marginBottom:"20px" }}>Ethnicities of students:</p>
          <ul style={{marginBottom:"20px"}}>
            <li className="p-md">Maori</li>
            <li className="p-md">Samoan</li>
            <li className="p-md">Fijian</li>
            <li className="p-md">Chinese</li>
            <li className="p-md">NZ European</li>
            <li className="p-md">Cook Island Maori</li>
            <li className="p-md">Nuie</li>
            <li className="p-md">Asian</li>
          </ul>
          <p className="p-lg" style={{marginBottom:"10px"}}><span style={{fontWeight:"bold"}}>Ages:</span> 17-37 years of age</p>
          <p className="p-lg"><span style={{fontWeight:"bold"}}>Gender:</span> 90% male, 10% female, 0% prefer not to say</p>
      </div>
      <div className="layout-container try-new-container">
        <h4 className="try-new-heading">
          Looking at trying out the new way of learning?
        </h4>
        <Link to="/contact">
          <Button colorSchema="sec">Contact us</Button>
        </Link>
      </div>
    </div>
  )
}

const IndexPage = () => {
  return (
    <Layout
      title="Accredited Category One NZQA private training provider"
      description="Accredited Category One NZQA private training provider"
    >
      <VisionSection />
      <CourseSection />
      <PathwaySection />
      <HowStudySection />
      <WhyChooseSection />
    </Layout>
  )
}

export default IndexPage
